import { jsx as _jsx } from "react/jsx-runtime";
import { Honeybadger, HoneybadgerErrorBoundary } from "@honeybadger-io/react";
import * as React from "react";
export let honeybadger = null;
function NodeContext({ apiKey, environment, revision, children, }) {
    const hb = React.useMemo(() => {
        return (honeybadger = Honeybadger.configure({
            apiKey,
            environment,
            revision,
        }));
    }, [apiKey, environment, revision]);
    React.useEffect(() => {
        const updateContext = () => {
            const metadata = Noodl.getMetaData();
            if (!metadata.cloudservices) {
                // we have no cloud service
                return;
            }
            const key = `Parse/${metadata.cloudservices.appId}/currentUser`;
            const item = localStorage.getItem(key);
            if (item) {
                try {
                    const currentUser = JSON.parse(item);
                    if (!currentUser) {
                        throw new Error("Undefined?");
                    }
                    hb.setContext({
                        user_id: currentUser.objectId,
                        user_email: currentUser.email,
                    });
                }
                catch (error) {
                    hb.notify(error);
                    hb.setContext({
                        user: item,
                    });
                }
            }
            else {
                hb.setContext({
                    user: null,
                    user_id: null,
                    user_email: null,
                });
            }
        };
        const handler = (event) => {
            // Match the "Parse/<appId>/currentUser" pattern
            if (event.key.startsWith("Parse/") &&
                event.key.endsWith("/currentUser")) {
                updateContext();
            }
        };
        window.addEventListener("storage", handler);
        updateContext();
        return () => {
            window.removeEventListener("storage", handler);
        };
    }, []);
    return (_jsx(HoneybadgerErrorBoundary, Object.assign({ honeybadger: hb }, { children: children })));
}
const nodeName = "honeybadger.context";
export const node = Noodl.defineReactNode({
    name: nodeName,
    displayName: "Honeybadger Context",
    category: "Visual",
    getReactComponent() {
        return NodeContext;
    },
    inputProps: {
        apiKey: {
            group: "Setup",
            type: "string",
            displayName: "API Key",
        },
        environment: {
            group: "Setup",
            type: "string",
            displayName: "Environment",
        },
        revision: {
            group: "Setup",
            type: "string",
            displayName: "Revision",
        },
    },
});
